var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "cards placeholders" }, [
    _c(
      "div",
      { staticClass: "card" },
      [
        _c("content-placeholders", [
          _c(
            "div",
            { staticClass: "card__inner" },
            [
              _vm.$route.name === "userDrafts"
                ? _c("div", { staticClass: "card__draft" }, [_c("button")])
                : _vm._e(),
              _vm._v(" "),
              _c("content-placeholders-heading", { attrs: { img: true } }),
              _vm._v(" "),
              _c("div", { staticClass: "dots" }, [
                _c("div", { staticClass: "dot" }),
              ]),
              _vm._v(" "),
              _c("content-placeholders-img"),
              _vm._v(" "),
              _c("div", { staticClass: "card-ctas-wrap" }, [
                _c("div", { staticClass: "placeholder-icons" }, [
                  _c("div", {
                    staticClass: "vue-content-placeholders-heading__img",
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "vue-content-placeholders-heading__img",
                  }),
                ]),
                _vm._v(" "),
                _c("div", {
                  staticClass:
                    "card__social-from vue-content-placeholders-heading__img",
                }),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "card__content" },
                [_c("content-placeholders-text", { attrs: { lines: 3 } })],
                1
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "card" },
      [
        _c("content-placeholders", [
          _c(
            "div",
            { staticClass: "card__inner" },
            [
              _vm.$route.name === "userDrafts"
                ? _c("div", { staticClass: "card__draft" }, [_c("button")])
                : _vm._e(),
              _vm._v(" "),
              _c("content-placeholders-heading", { attrs: { img: true } }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "card__content" },
                [_c("content-placeholders-text", { attrs: { lines: 5 } })],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "card-ctas-wrap footer" }, [
                _c("div", { staticClass: "placeholder-icons" }, [
                  _c("div", {
                    staticClass: "vue-content-placeholders-heading__img",
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "vue-content-placeholders-heading__img",
                  }),
                ]),
                _vm._v(" "),
                _c("div", {
                  staticClass:
                    "card__social-from vue-content-placeholders-heading__img",
                }),
              ]),
            ],
            1
          ),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "card" },
      [
        _c("content-placeholders", [
          _c(
            "div",
            { staticClass: "card__inner big" },
            [
              _vm.$route.name === "userDrafts"
                ? _c("div", { staticClass: "card__draft" }, [_c("button")])
                : _vm._e(),
              _vm._v(" "),
              _c("content-placeholders-heading", { attrs: { img: true } }),
              _vm._v(" "),
              _c("content-placeholders-img"),
              _vm._v(" "),
              _c("div", { staticClass: "card-ctas-wrap" }, [
                _c("div", { staticClass: "placeholder-icons" }, [
                  _c("div", {
                    staticClass: "vue-content-placeholders-heading__img",
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "vue-content-placeholders-heading__img",
                  }),
                ]),
                _vm._v(" "),
                _c("div", {
                  staticClass:
                    "card__social-from vue-content-placeholders-heading__img",
                }),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "card__content" },
                [_c("content-placeholders-text", { attrs: { lines: 3 } })],
                1
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "card" },
      [
        _c("content-placeholders", [
          _c(
            "div",
            { staticClass: "card__inner" },
            [
              _vm.$route.name === "userDrafts"
                ? _c("div", { staticClass: "card__draft" }, [_c("button")])
                : _vm._e(),
              _vm._v(" "),
              _c("content-placeholders-heading", { attrs: { img: true } }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "card__content" },
                [_c("content-placeholders-text", { attrs: { lines: 4 } })],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "card-ctas-wrap footer" }, [
                _c("div", { staticClass: "placeholder-icons" }, [
                  _c("div", {
                    staticClass: "vue-content-placeholders-heading__img",
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "vue-content-placeholders-heading__img",
                  }),
                ]),
                _vm._v(" "),
                _c("div", {
                  staticClass:
                    "card__social-from vue-content-placeholders-heading__img",
                }),
              ]),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }